import React from 'react';
import './header.css';
import people from '../../assets/people.png';
import sly from '../../assets/sly.png';

const Header = () => {
    return (
        <div className='cks__header section__padding id="home'>
            <div className='cks__header-content'>
                <h1 className='gradient__text'>About Orange</h1>

                <p>Orange was created as a unique platform to serve the purpose of taking Art encapsulated with solutions as a social and organizational vehicle in order to create a platform for creatives and take Art to its lovers at various demography.
                </p>

                <p>Orange serves as a means for creatives who have no organized capacity to showcase their craft, and a means to disrupt the status quo. Chiefly synonymous with its parent form being the citrus fruit, Orange events is poised to open up countless juicy and succulent parts, with creatives making up the seedlings.
                </p>

                <div className='cks__header-content__input'>
                    <button type='button'>
                        <a href='https://docs.google.com/forms/d/e/1FAIpQLSfMwH9O2Rov635GCON87vEvMzXLw4-by67QbtEldmQXUWshHQ/viewform'>
                        Subscribe
                        </a>
                    </button>
                </div>

                <div className='cks__header-content__people'>
                    <img src={people} alt="people"/>
                    <p>Over 2000 people have visited our exhibitions</p>
                </div>
            </div>
            <div className='cks__header-image'>
                <img src={sly} alt="sly"/>
            </div>
        </div>
    )
}

export default Header
