import React from 'react';
import { Feature } from'../../components';
import './features.css';

const featuresData = [
    {
        title: 'Orange Tour: An Annual Event Across Nigeria and Beyond',
        text: "Orange is an annual event that is aimed at visiting various states within and outside Nigeria"
    },
    {
        title: "Orange's Creative Solutions",
        text: "Orange aims to solve and address societal creative issues"
    },
    {
        title: 'Orange Art: A New Generation of Creative Expression',
        text: "Orange Art events would bring together creatives, consumers, thus creating an avenue for the new generation of creators who are seeking ways to express themselves but haven't been able to do so due to the usual hegemony in the system. "
    },
    {
        title: 'Orange: Niche Creative Solutions.',
        text: "Orange would connect organizations and brands with creative solutions that are niche specific"
    },

]

const Features = () => {
    return (
        <div className='cks__features section__padding' id="features">
            <div className='cks__features-heading'>
                <h1 className='gradient__textt'>Orange: Unlocking Juicy Opportunities For Creatives and Art Lovers of Every Demographic</h1>
                <p>Sign Up for Our Newsletter</p>
            </div>
            <div className='cks__features-container'>
                {featuresData.map((item, index) => (
                    <Feature title={item.title} text={item.text} key={item.title + index} />
                ))}
            </div>
        </div>
    )
}

export default Features;
