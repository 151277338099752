import React from 'react';
import {Article} from '../../components';
import './blog.css';
import img1 from '../../assets/img1.jpg';
import img7 from '../../assets/img7.jpg';
import img3 from '../../assets/img3.jpg';
import img4 from '../../assets/img4.jpg';
import img5 from '../../assets/img5.jpg';


const Blog = () => {
    return (
        <div className='cks__blog section__padding' id="blog">
            <div className='cks__blog-heading'>
                <h1 className='gradient__textt'>A lot is happening, We are blogging about it</h1>
            </div>
            <div className='cks__blog-container'>
                <div className='cks__blog-container_groupA'>
                    <a href="https://www.reuters.com/article/ctech-us-crypto-currency-nigeria-idCAKBN2H20VM-OCATC"> 
                        <Article imgUrl={img3} date="Jan 15, 2023" title="How Artists are impacting the world of Crypto Currency"/>
                    </a> 
                </div>

                <div className='cks__blog-container_groupB'>
                    <a href="https://www.sunnewsonline.com/matrix-gallery-holds-exhibition/"> 
                        <Article imgUrl={img1} date="Jan 17, 2023" title="Cokespice Paints Abuja Orange from 9-11 December 2022"/>
                    </a>

                    <a href="https://docs.google.com/document/d/10vpb62FXIMDryzYB1LhZuaQwYCWf5Mf8_L3gmLpsuqw/edit"> 
                        <Article imgUrl={img7} date="March 18, 2023" title="Exploring the Productive and effective brand partnerships for Orange Events"/>
                    </a>
            
                    <Article imgUrl={img5} date="Jan 15, 2023" title="An exhibition that embraces all"/>

                    <Article imgUrl={img4} date="Jan 15, 2023" title="Check out Sly Megida's performance"/>
                </div>
            </div> 
        </div>
    )
}

export default Blog
