import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri'
import './navbar.css';

const Menu = () => (
    <>
        <p><a href='#home'>Home</a></p>
        <p><a href='#possibility'>Artists</a></p>
        <p><a href='#cokespice'>CokeSpice</a></p>
        <p><a href='#features'>Gallery</a></p>
        <p><a href='#blog'>About Us</a></p>
    </>
)

const Navbar = () => {
    const [ toggleMenu, setToggleMenu ] = useState(false);

    return (
        <div className='cks__navbar'>
            <div className='cks__navbar-links'>
                <div className='cks__navbar-links_logo'>
                    <h1>O   R   A   N   G   E</h1>
                </div>
                <div className='cks__navbar-links_container'>
                    <Menu />
                </div>
            </div>
            <div className='cks__navbar-contact'>
                <button type="button">Contact us</button>
            </div>
            <div className='cks__navbar-menu'>
                {toggleMenu
                    ? <RiCloseLine color='#0b1529' size={27} onClick={() => setToggleMenu(false)} />
                    : <RiMenu3Line color='#0b1529' size={27} onClick={() => setToggleMenu(true)} />
                }
                {toggleMenu && (
                    <div className='cks__navbar-menu_container scale-up-center'>
                        <div className='cks__navbar-menu_container-links'>
                            <Menu />
                            <div className='cks__navbar-menu_container-links-contact'>
                                <button type="button">Contact us</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Navbar
