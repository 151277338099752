import React from 'react';

import possibilityImage from '../../assets/possibility1.png';
import './possibility.css';

const Possibility = () => {
    return (
        <div className='cks__possibility section__padding' id="possibility">
            <div className='cks__possibility-image'>
                <img src={possibilityImage} alt="possibility"/>
            </div>
            <div className='cks__possibility-content'>
                <h4>
                <a href="https://drive.google.com/drive/folders/1tWIf5fo_7P2eNpqWCDgiAIwsek5ocO78">
                Take a look at our gallery
                </a>
                </h4>
                <h1 className='gradient__textt'> <a href="https://www.instagram.com/experience_orange/">Visit our instagram</a></h1>
                <p>Navigate our social media and have a look of the best moments from our previous exhibitions.</p>
                <h4> <a href="https://drive.google.com/drive/folders/1tWIf5fo_7P2eNpqWCDgiAIwsek5ocO78">
                Take a look at our gallery
                </a>
                </h4>
            </div>
        </div>
    )
}

export default Possibility
