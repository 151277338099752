import React from 'react';
import './cokeSpice.css';
import { Feature } from '../../components';

const CokeSpice = () => {
    return (
        <div className='cks__definition section__margin' id="cksp">
            <div className='cks__definition-feature'>
                <Feature title="Who we are" text="Cokespice is an Art-Tech Media Company; Our specialty is tailoring artistic solutions into relevant resources useful in solving societal problems. We create and add value through African Art, NFT, Murals, Exhibitions such as Orange Events and Art installations. 
                "/>
            </div>
            <div className='cks__definition-heading'>
                <h1 className='gradient__textt'>
                    Explore the Orange experience
                </h1>
                <p> <a href="https://www.instagram.com/experience_orange/">Explore our gallery</a></p>
            </div>
            <div className='cks__definition-container'>
                <Feature title="Artists" text="The Orange Exhibition is hosting an exciting new exhibition featuring the works of up-and-coming artists from around the Africa. This diverse group of artists bring a unique perspective to their works, ranging from traditional mediums to digital art."/>
                <Feature title="NFTs" text="The Orange Exhibition is hosting a special exhibition of Non Fungible Tokens (NFTs). This exhibition will showcase a variety of NFTs from artists across the globe. Artworks include digital art, music, photography, and more."/>
                <Feature title="Fashion" text="The Orange Art Exhibition Fashion Runway is an exciting event that showcases the latest fashion trends from some of the most renowned designers around the world. The event features a runway show featuring the latest designs from top fashion houses and emerging designers."/>
            </div>
        </div>
    )
}

export default CokeSpice
