import React from 'react';
import './brand.css';
import { bitcasino, sportsbetio } from './imports';

const Brand = () => {
    return (
        <div className='cks__brand section__padding'>
            <div>
                <img src={bitcasino} alt="bitcasino"/>
            </div>
            <div>
                <img src={sportsbetio} alt="sportsbetio"/>
            </div>
        </div>
    )
}

export default Brand
