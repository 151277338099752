import React from 'react';
import './cta.css';

const Cta = () => {
    return (
        <div className='cks__cta'>
            <div className='cks__cta-content'>
                <p>Get Notified of our upcoming events</p>
                <h3>Subscribe and sign up for our newsletter</h3>
            </div>
            <div className='cks__cta-btn'>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSfMwH9O2Rov635GCON87vEvMzXLw4-by67QbtEldmQXUWshHQ/viewform">
                    <button>Subscribe</button>
                </a> 
            </div>
        </div>
    )
}

export default Cta
