import React from 'react';
import './footer.css';
import cksLogo from '../../assets/logo1.png'
import whatsapp from '../../assets/whatsapp.png'
import instagram from '../../assets/instagram.png'
import youtube from '../../assets/youtube.png'
import twitter from '../../assets/twitter.png'

const Footer = () => {
    return (
        <div className='cks__footer section__padding'>
            <div className='cks__footer-heading'>
                <h1 className='gradient__textt'>Do you want to be a part of our next exhibition?</h1>
            </div>

            <div className='cks__footer-btn'>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSfMwH9O2Rov635GCON87vEvMzXLw4-by67QbtEldmQXUWshHQ/viewform">
                    <p>Become a partner</p>
                </a>  
            </div>

            <div className='cks__footer-links'>
                <div className='cks__footer-links_logo'>
                    <img src={cksLogo} alt="logo" />
                </div>

                <div className='cks__footer-links_div'>
                    <div className='cks__footer-links_logo'>
                        <a href="/"><img src={whatsapp} alt="whatsapp"/></a>
                    </div>
                    <div className='cks__footer-links_logo'>
                        <a href="https://www.instagram.com/experience_orange/"><img src={instagram} alt="instagram"/></a>
                    </div>
                    <div className='cks__footer-links_logo'>
                        <a href="/"><img src={twitter} alt="twitter"/></a>
                    </div>
                    <div className='cks__footer-links_logo'>
                        <a href="/"><img src={youtube} alt="youtube"/></a>
                    </div>
                </div>
                <div className='cks__footer-links_div'>
                    <h4>Company</h4>
                    <p>Terms & Consitions Media</p>
                    <p>Privacy Policy</p>
                    <p>Contact</p>
                </div>
                <div className='cks__footer-links_div'>
                    <h4>Get in Touch</h4>
                    <p>Lagos, Nigeria</p>
                    <p>+234 703 278 4475</p>
                    <p>+234 902 985 3796</p>
                    <p>Cokespiceart@gmail.com</p>
                </div>
            </div>

            <div className="cks__footer-copyright">
                <p>@2023 cokespice. All rights reserved.</p>
            </div>
        </div>
    )
}

export default Footer
